@import url("https://fonts.googleapis.com/css?family=Arimo|Barlow|Barlow+Semi+Condensed|Muli|Nunito|Titillium+Web|Fira+Sans|Manrope");

$body-family: 'Fira Sans', serif;


.row {
  display:flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}
.columns {
  display: flex;
  justify-content: center;
}
.column {
  display: flex;
  flex-direction: column;
  -webkit-flex-direction: column;
}

//*:focus {
//  outline: none;
//  border: 0;
//  box-shadow: none;
//}

html, body, .App {
  height: 100%;

  -webkit-tap-highlight-color: rgba(0,0,0,0) !important; /* prevent tap highlight color / shadow */
  -webkit-tap-highlight-color: transparent !important; /* (for some Androids) */
}
html, body, .App, #root {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  flex-grow: 1;
}

.main-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

body {
  background-color: #e8e8e8;
}

*, :after, :before {
  box-sizing: inherit;
}


.container {
  padding: 1em;
  margin: auto;
}
@media screen and (min-width: 1408px) {
  .container {
    max-width: 1600px !important;
  }
}


.page-title {
  span {
    color: $aries-purple;
  }
}


.recentness-data {
  text-align: center;
  font-size: 0.85em;
  font-style: italic;
  padding: 0 1em;
}


.control {
  box-sizing: border-box;
  clear: both;
  font-size: 1rem;
  position: relative;
  text-align: left;

  &.has-icon {
    .icon {
      color: #dbdbdb;
      height: 2.5em;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 2.5em;
      z-index: 4;

      align-items: center;
      display: inline-flex;
      justify-content: center;
    }

    .input {
      padding-left: 2.5em;
    }
  }
}

.searchAndFilterBar {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -webkit-flex-direction: row;
  padding: 4px;
  justify-content: space-between;
  margin: auto;
  max-width: 1200px;

  .column {
    flex: 1;
    padding-bottom: 1em;
    margin: 4px;

    &.sortBy {
      align-items: flex-end;
    }

    > div {
      min-width: 18em;
      max-width: 25em;
      margin: auto;
    }
  }

  .sortBy > div {
    @media screen and (min-width: 769px) {
      float: right !important;
    }
  }


  .searchBox {
    .icon, .input {
      height: 3em !important;
    }
    .input {
      border: 0;
      border-radius: 5px;
      font-size: 15px;
      //font-weight: bold;
    }
  }
}



.system-header {
  height: 200px;

  .back-link {
    color: white;
    font-size: 1em;
    font-weight: bold;
    margin: 0.75em;
    position: absolute;

    &:before {
      border-left: 3px solid white;
      border-top: 3px solid white;
      width: 12px;
      content: '';
      height: 12px;
      transform: rotate(-45deg);
      /* margin-top: 37px; */
      margin-left: 0.5em;
      margin-right:9px;
      float: left;
    }
  }

  .system-name {
    text-align: center;
    color: white;
    font-weight: 900;
    padding-top: 42px;
    padding-left: 16px;
    padding-right: 16px;
    height: 64px;
    min-width: 268px;
    margin: 0 auto;
    justify-content: center;
    align-items: end !important;
  }
}
.system-header-overlay {
  transform: translateY(-45%);
  max-width: 900px;
  margin: auto;
  padding: 0 1em;

  @media (max-width: 767px) {
    transform: translateY(-25%);
    max-width: 400px;
  }
}

.clickable {
  cursor: pointer;
}
.not-clickable {
  cursor: not-allowed;
}



.footer {
  text-align: center;
  padding: 1em;
  background: $aries-purple;
  color: white;

  .footer-text {
    min-height: 50px;
    font-size: 1em;
    line-height: 1.5em;
    font-weight: 300;
    letter-spacing: 1px;
  }

  a {
    color: white;
    font-weight: 500;
    text-transform: initial;
  }
}

.system-card-wrapper {
  max-width: 900px;
  width: 100%;
  margin: auto;

  @media (max-width: 600px) {
    max-width: 400px;
  }
}


hr {
  border-top: 1px solid #aaaaaa;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.mh-tooltip-target {
  position: relative;
}

.mh-tooltip-target:after {
  padding: 24px;
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Trends {
  a {
    text-transform: initial;
    letter-spacing: initial;
  }

  .local-footer {
    margin: 2em;
  }
}

.Reports {
  padding-bottom: 3em;

  a {
    text-transform: initial;
    letter-spacing: initial;
  }
}

.VehicleHistory, .StopHistory {
  .table-wrapper {
    font-size: unquote("min(1.2vw, 16px) !important");
    margin-bottom: 2em;

    .table-title {
      position: sticky;
      top: 0;
      padding: 0.5em 0;
      background-color: #e8e8e8;
      font-size: unquote("min(1.6vw, 18px) !important");
      font-weight: 500;
      z-index: 1;  // ensures tooltip targets are rendered under this
    }

    table {
      width: 100%;
      border-spacing: 0;

      tr.table-header {
        position: sticky;
        top: 2.35em;
        background: #754BAF;
        color: white;
        z-index: 1;  // ensures tooltip targets are rendered under this

        th {
          padding: 0.4em;
        }
      }

      tr.table-row {
        &:nth-child(odd) {
          background: #CCC;
        }

        &.highlight {
          background: #40c4ff;

          a:hover {
            color: #000 !important;
          }
        }

        td {
          line-height: unquote("min(1.2vw, 16px) !important");
          padding: 0.5em;
        }
      }
    }
  }
}

button {
  background-color: #42c4fe;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  color: white;
  font-weight: 600;
  font-family: "Roboto","Helvetica",sans-serif;
  letter-spacing: 1px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #45b5e7;
  }
}

.permalink-button {
  font-size: inherit;
  padding: inherit;
  border: #42c4fe 0.1em solid;
  background-color: inherit;

  &:hover {
    background-color: #45b5e7;
  }
}
