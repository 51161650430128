.Dropdown-arrow {
  top: 1.2em;
}

.Dropdown-control {
  border: none;
  border-left: 1px solid #ccc;
  border-radius: 0 5px 5px 0;
  padding: 0.75em;
}
