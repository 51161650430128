/* header */

.header {
  background-color: #fff;
  box-shadow: 1px 1px 4px 0 rgba(0,0,0,.1);
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  flex-wrap:wrap;
  border-bottom: 1px solid darkgray;
  padding: 0.25em 0;
}

.header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  background-color: #fff;
}

.header {
  a {
    text-decoration: none;
    font-weight: bold;
    color: $aries-purple;
  }

  li a {
    display: block;
    padding: 20px;
    font-weight: 800;
    font-size: 1.15em;

    @media (max-width: 528px) {  // MODIFY ME IF ADDING STUFF TO NAVBAR
      padding: 12px;
    }

    @media (max-width: 431px) {  // MODIFY ME IF ADDING STUFF TO NAVBAR
      font-size: 1em;
      padding: 8px;
    }

    @media (max-width: 360px) {  // MODIFY ME IF ADDING STUFF TO NAVBAR
      font-size: 1em;
      padding: 6px;
    }
  }
}

.header .logo {
  display: flex;
  font-size: 3em;
  text-decoration: none;
  align-items: baseline;
  letter-spacing: -2px;
  text-transform: lowercase;
  margin-left: 18px;
}

/* menu */

.header .menu {
  clear: both;
  max-height: 0;
  transition: max-height .2s ease-out;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  margin-left: auto;
  order: 2;
}

/* menu icon */

.header .menu-icon {
  cursor: pointer;
  display: inline-block;
  //float: right;
  padding: 28px 20px;
  position: absolute;
  right: 0;
  user-select: none;
}

.header .menu-icon .navicon {
  background: #333;
  display: block;
  height: 2px;
  position: relative;
  transition: background .2s ease-out;
  width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: #333;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 5px;
}

.header .menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */

.header .menu-btn {
  display: none;
}

.header .menu-btn:checked ~ .menu {
  max-height: 240px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

/* 48em = 768px */

@media (min-width: 805px) {  // MODIFY ME IF ADDING STUFF TO NAVBAR
  .header li {
    //float: left;
  }
  .header .menu {
    //display: flex;
    //clear: none;
    //float: right;
    max-height: none;
  }
  .header .menu-icon {
    display: none;
  }
}
