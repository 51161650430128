.faq-sidebar {
  font-size: 1.1em;
  font-weight: bold;
  margin-top: 5em;
  //position: sticky;
  //top: 20px;

  @media screen and (max-width: 768px) {
    display: none;
  }

  ul {
    padding-left: 0;
    li {
      list-style: none;
      text-align: right;
      margin: 0 3em 1em 0;
    }
  }
}

article {
  font-size: 18px;
  line-height:27px;

  @media screen and (max-width: 768px) {
    padding: 0 1em;
  }

  a {
    text-transform: initial;
    letter-spacing: initial;
  }

  section {
    padding-bottom: 1.5em;
  }

  ul {
    li {
      margin-bottom:0.75em;
      font-size:0.9em;
    }
  }
}
