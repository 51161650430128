.system-card {
  background: white;
  box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.3);
  border-radius:5px;
  margin: auto auto 2em;


  &.is-link {
    //position: relative;
    //display: inline-block;
    //width: 100px;
    //height: 100px;
    //background-color: #fff;
    //border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    //border-radius: 5px;
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

    &::after {
      content: "";
      border-radius: 5px;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
      opacity: 0;
      -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
      transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    &:hover {
      -webkit-transform: scale(1.01, 1.01);
      transform: scale(1.01, 1.01);
    }

    &:hover::after {
      opacity: 1;
    }
  }



  .arrival-frequency {
    margin-top: 2.5em;
    text-align: center;

    .heading {
      font-size: 14px;
      letter-spacing: 0 !important;
      margin-bottom: 0.5em;
    }
  }

  .addendum-text {
    font-style: italic;
    font-size: 0.9em;
    padding-left: 5px;
  }

  .system-logo {
    position: absolute;
    top: 0;
    right: 0;
    height: 50px;
    width: 100px;
    opacity: 0.3;
    text-align: right;

    display: none;

    @media screen and (min-width: 767px) {
      display: block;
    }
  }

  .system-marker {
    color: white;
    font-size: 2em;
    font-weight: 900;
    display: flex;
    align-items: center !important;
    justify-content: center;
    text-align: center;
    padding: 0.3em;

    width: 100%;
    height: 100%;

    @media screen and (min-width: 767px) {
      width: 4.5em;
      height: 4.5em;
    }
  }


  .stats {
    .heading {
      margin-bottom: 0;
      letter-spacing: 0 !important;
    }

    .head-stats {
      margin: 1em 0;
      padding: 0 0.5em;
      text-align: center;

      .stat svg {
        width: 1.5em;
        height: 1.5em;
      }
    }
  }

  .stat {
    display: inline-block;
    width: 50%;
  }


  .small-stats {
    text-align: center;

    margin-bottom: 0 !important;

    .stat {
      vertical-align: top;
    }
  }

  .system-card-section {
    width: 17em;

    .stat-percent {
      font-size: 3rem;
      font-weight: bold;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      padding-right: 0.5em;

      @media (max-width: 767px) {
        padding-top: 0.25em;
      }

      .not-available {
        font-weight: inherit;
        color: #aaaaaa;
      }
    }

    @media (max-width: 767px) {
      flex-grow: 1;
      align-items: center;
    }
  }

  .system-card-section-item-container {
    width: 16em;
    flex-grow: 1;

    @media (max-width: 600px) {
      flex-basis: 100%;
    }
  }

  .system-card-section-item {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 0.9em;
    margin: 1em 0;
    padding-left: 1.5em;

    @media (min-width: 767px) {
      border-left: 2px solid gray;
    }
  }


  .num-reporting {
    font-size: 0.7em;
    text-transform: lowercase;

    .percent-reporting {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 16px;
    }

    @media (max-width: 767px) {
      flex-basis: 100%;
    }
  }
}


