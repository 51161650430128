
// brand colors
$aries-purple: #754BAF;
$aries-blue: #40c4ff;


// status colors
$grey: #aaaaaa;
$red: #c03333;
$orange: #e1793f;
$yellow: #e2b31e;
$greenish: #9dc73a;
$green: #588d5d;


$body-background-color: #e8e8e8;

$container-offset: 0px;

$hr-margin: 0;

$link: #3f51b5;
$link-hover: #3f51b5;
$link-active: #3f51b5;




a {
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  color: $aries-purple;
  letter-spacing: 1px;

  &.secondary {
    color: $aries-blue;
  }

  &:not(.logo):not(.back-link):hover {
    color: $aries-blue;
  }
}
