.route-card {
    position: relative;
    background: white;
    box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    width: 284px;
    margin: auto;

    .arrival-frequency {
        margin-top: 2.25em;

        .heading {
            font-size: 13px;
            letter-spacing: 0 !important;
            font-style: italic;
            text-align: center;
        }
    }

    .heading.main {
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 500;
        margin-top: 3px;
    }

    .route-marker {
        font-weight: bold;
        display: block;
        height: auto;
        border-radius: 5px;
        color: white;
        text-align: center;
        z-index: 10;
        margin: auto;
        padding: 0.3em 0.2em;
        position: absolute;
        width: 80%;
        top: 0;
        right: 0;
        left: 50%;
        transform: translate(-50%, -50%);

        .route-id {
            display: table-cell;
            vertical-align: middle;
            justify-content: center !important;
            padding: 2px 8px;
        }
    }


    .route-card-section-item {
        min-height: 3em;
        color: #434343;
        margin-top: 0.75em;

        .heading {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }


    .stats {
        padding: 0.5em 0.25em;

        .head-stats {
            margin-bottom: 1em;
            text-align: center;

            .stat svg {
                width: 1.5em;
                height: 1.5em;
            }
        }

        .tooltip {
            position: absolute;
            // TODO lol whatever
            padding-top: 1px;
        }
    }

    .stat {
        flex-grow: 1;
        width: 50%;
    }

    .small-stats {
        text-align: center;
        font-size: 0.85em;
        line-height: 20px;

        margin-bottom: 0 !important;

        .stat {
            vertical-align: top;
        }
    }

    .stat-percent {
        font-size: 2.25rem;
        font-weight: bold;

        .not-available {
            font-size: 0.8em;
            font-weight: initial;
            color: #aaaaaa;
        }


    }

    .num-reporting {
        font-size: 0.85em;
        text-transform: lowercase;

        .percent-reporting {
            padding: 0.5em;
            margin-bottom: 0.5em;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}



////////////////////////////////
/// colors
///////////////////////////////
.route-card, .system-card {
    &.gray {
        border: 2px solid $grey;
        > .route-marker, .system-marker {
            background: $grey;
        }
    }
    &.red {
        border: 2px solid $red;
        > .route-marker, .system-marker {
            background: $red;
        }
    }
    &.orange {
        border: 2px solid $orange;
        > .route-marker, .system-marker {
            background: $orange;
        }
    }
    &.yellow {
        border: 2px solid $yellow;
        > .route-marker, .system-marker {
            background: $yellow;
        }
    }
    &.greenish {
        border: 2px solid $greenish;
        > .route-marker, .system-marker {
            background: $greenish;
        }
    }
    &.green {
        border: 2px solid $green;
        > .route-marker, .system-marker {
            background: $green;
        }
    }
}


.system-header {
    &.gray {
        border: 2px solid $grey;
        background: $grey;
    }
    &.red {
        border: 2px solid $red;
        background: $red;
    }
    &.orange {
        border: 2px solid $orange;
        background: $orange;
    }
    &.yellow {
        border: 2px solid $yellow;
        background: $yellow;
    }
    &.greenish {
        border: 2px solid $greenish;
        background: $greenish;
    }
    &.green {
        border: 2px solid $green;
        background: $green;
    }
}


.stat-percent.green {
    color: $green;
}
.stat-percent.greenish {
    color: $greenish;
}
.stat-percent.yellow {
    color: $yellow;
}
.stat-percent.orange {
    color: $orange;
}
.stat-percent.red {
    color: $red;
}
