
.sortBy-dropdown {
  width:100%;
  display: flex;
  justify-content: flex-start;

  .button {
    background-color: white;
    border: 0;
    font-size: 15px;
    font-weight: bold;
  }
  .icon {
    visibility: hidden;
  }

  .dropdown-menu {
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
  }

  .dropdown-trigger .button {
    box-sizing: border-box;
    border-radius: 0 5px 5px 0;
    border-left: 1px solid #dbdbdb;
    text-align: left;
    justify-content: left;
    width:100%;
    height: 100%;
  }

  .sortBy-toggle {
    border-radius: 5px 0 0 5px;
  }
}


.level, .level-item, .dropdown, .dropdown > *, .dropdown-trigger > * {
  width: 100%;
}








.button {
  background-color: #fff;
  border-color: #dbdbdb;
  border-width: 1px;
  color: #363636;
  cursor: pointer;
  justify-content: center;
  padding: calc(.5em - 1px) 1em;
  text-align: center;
  white-space: nowrap;
}


.dropdown {
  display: inline-flex;
  position: relative;
  vertical-align: top;
  &.is-active,
  &.is-hoverable:hover {
    .dropdown-menu {
      display: block; } }
  &.is-right {
    .dropdown-menu {
      left: auto;
      right: 0; } }
  &.is-up {
    .dropdown-menu {
      bottom: 100%;
      //padding-bottom: $dropdown-content-offset;
      padding-top: initial;
      top: auto; } } }

.dropdown-menu {
  display: none;
  left: 0;
  margin-top: 2px;
  position: absolute;
  top: 100%;
  z-index: 10000;
}

.dropdown-content {
  background-color: white;
  padding: 0.75em 0.25em;
  border-radius: 5px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.02);
}

.dropdown-item {
  //color: $dropdown-item-color;
  display: block;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 0.375rem 1rem;
  position: relative; }

a.dropdown-item,
button.dropdown-item {
  padding-right: 3rem;
  text-align: left;
  white-space: nowrap;
  width: 100%;

  &:hover {
    //background-color: $dropdown-item-hover-background-color;
    //color: $dropdown-item-hover-color;
  }

  &.is-active {
    //background-color: $dropdown-item-active-background-color;
    //color: $dropdown-item-active-color; }
  }

  .dropdown-divider {
    //background-color: $dropdown-divider-background-color;
    border: none;
    display: block;
    height: 1px;
    margin: 0.5rem 0;
  }
}
